import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TurfService } from '../services/turf.service';
import { PermissionDeniedService } from '../shared/permission-denied/permission-denied.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard  {
  previousUrl: string;

  constructor(
    private turfService: TurfService,
    private router: Router,
    private permissionDeniedService: PermissionDeniedService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const permission = childRoute.data.permission ?? state.url;

    const isAuthorized = this.turfService.isAuthorized(permission, 'view');

    if (!isAuthorized) {
      this.permissionDeniedService.show();
      if (this.previousUrl) {
        this.router.navigateByUrl(this.previousUrl);
      }
    }

    return isAuthorized;
  }
}
