import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../constants/endpoints';
import { LoaderService } from './common-helper-service/loder.service';
import { RequestService } from './request.service';
import { StorageService } from './storage.serviceV2';

@Injectable({
  providedIn: 'root',
})
export class ImpersonateService {
  impersonateStatus = false;
  impersonate = null;

  multiFacilities: any = {};
  userFacilityTimezone: any;

  user;

  impersonateLabel = 'Start Impersonate';

  headers: any = {};

  impersonateHeaderStyle = {
    'background-color': '#f05050',
  };

  constructor(
    private storageService: StorageService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private requestService: RequestService,
    private translate: TranslateService
  ) {
    this.user = this.storageService.user;
    this.headers = {
      authorization: this.storageService.authorizationToken,
    };
    if (localStorage.impersonate) {
      this.impersonate = JSON.parse(localStorage.impersonate);
      this.impersonateStatus = true;
    }
    this.updateImpersonateLabel();
  }

  multiFacilityNames() {
    let names = this.multiFacilities.selected.map((item) => {
      if (item.id == this.user.currentFacilityId) {
        this.userFacilityTimezone = item.timeZone;
      }
      return item['name'];
    });
    this.multiFacilities.names = names.join();
  }

  updateImpersonateLabel() {
    if (this.impersonate) {
      this.impersonateLabel = 'Stop Impersonate';
      this.impersonateStatus = true;
    } else {
      this.impersonateLabel = 'Start Impersonate';
      this.impersonateStatus = false;
    }
  }

  startImpersonate(user) {
    this.headers = {
      authorization: this.storageService.authorizationToken,
    };
    // usSpinnerService.spin('spinner-1');
    this.loaderService.showLoader();

    // let user = this.user;

    if (this.userFacilityTimezone != '') {
      user.zone = this.userFacilityTimezone;
    } else {
      this.translate.get('HeyFacilityTimezoneHasntBeenSetUpYetThereforeAllTheCalculationWillBeDoneOnTheBasisOfAmericaNewYork').subscribe(message => {                
        this.toastrService.info(message, this.loaderService.notificationTitle.info);
      })  
      user.zone = 'America/New_York';
    }
    user.impersonatedAccountName = user.firstname + ' ' + user.lastname;
    // User.startImpersonate({ id: user.id, data: user }).$promise
    const body = { id: user.id, data: user };
    this.requestService
      .send<any>('POST', `${endpoints.users}/startImpersonate?id=${body.id}`, {
        body: body,
        headers: this.headers,
      })
      .then(
        (res) => {
          this.impersonate = {
            id: user.id,
            email: user.username,
            name: user.impersonatedAccountName,
            zone: user.zone,
            createdBy: user.createdBy,
            organizationId: user.organizationId,
            userFacility: Boolean(user.userFacility)
              ? user.userFacility
              : user?.facilities?.map((i) => i.id).join(','),
          };          

          this.impersonateStatus = true;

          // localStorage.impersonate = this.impersonate;
          localStorage.setItem('impersonate', JSON.stringify(this.impersonate));

          const localUser = this.storageService.user;
          localUser.impersonateUserId = user.id;
          localUser.impersonateUserName = user.username;
          localUser.organizationId = user.organizationId;
          // localStorage.setItem('user', JSON.stringify(localUser));
          this.storageService.user = localUser;
          // $localStorage.user.impersonateUserId = user.id;
          // $localStorage.user.impersonateUserName = user.username;

          user.facilities.forEach((d) => {
            if (d.id == user.currentFacilityId) {
              localStorage.zone = d.timeZone;
            }
          });

          this.updateImpersonateLabel();
          this.translate.get('please-wait').subscribe((message) => {
            this.toastrService.info(
              message,
              this.loaderService.notificationTitle.info
            );
          });
          setTimeout(() => {
            // usSpinnerService.stop('spinner-1');
            window.location.reload();
          }, 300);
        },
        (err) => {
          // usSpinnerService.stop('spinner-1');
          console.log(err);
          this.loaderService.hideLoader();
          this.toastrService.error(err?.data?.error?.message, 'Error');
        }
      );
  }

  stopImpersonate() {
    if (this.impersonate) {
      // usSpinnerService.spin('spinner-1');
      this.loaderService.showLoader();

      // User.stopImpersonate().$promise
      this.requestService
        .send<any>('POST', `${endpoints.users}/stopImpersonate`, {
          body: {},
          headers: this.headers,
        })
        .then(
          (res) => {
            localStorage.zone = 'America/New_York';

            this.impersonate = null;
            // localStorage.impersonate = this.impersonate;
            localStorage.removeItem('impersonate');

            this.impersonateStatus = false;

            const user = this.storageService.user;
            user.impersonateUserId = null;
            user.impersonateUserName = null;
            user.impersonatedAccountName = null;
            user.currentFacilityId = 1; // data takes little time to load 
            // localStorage.setItem('user', JSON.stringify(user));
            this.storageService.user = user;

            this.updateImpersonateLabel();
            this.translate.get('please-wait').subscribe((message) => {
              this.toastrService.info(
                message,
                this.loaderService.notificationTitle.info
              );
            });
            setTimeout(() => {
              // usSpinnerService.stop('spinner-1');
              window.location.reload();
            }, 300);
          },
          (err) => {
            // usSpinnerService.stop('spinner-1');
            this.loaderService.hideLoader();
            this.toastrService.error('Error', err.data.error.message);
          }
        );
    }
  }
}
